import React, { useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { MENU_HEADER_HEIGHT } from "../components/MenuListHeader";
import MenuCartListItem from "../components/MenuCartListItem";

import styles from "./Cart.module.css";

import { TABLE } from "../constants";
import { THEME_COLOR2 } from "./MenuList2";
import { getOrdersWithTotalPrice } from "../constants/commonFunc";

let PADDING_HORIZONTAL = 16;
let BTN_HEIGHT = 60;
let PRICE_HEIGHT = 65;
// let VIEW_HEIGHT = BTN_HEIGHT + 30;
let VIEW_HEIGHT = 70;
let BTN_HEIGHT_2 = VIEW_HEIGHT - 10;
let FOOTER_HEIGHT = PRICE_HEIGHT + VIEW_HEIGHT + BTN_HEIGHT;
let CONTENT_HEIGHT = window.innerHeight - FOOTER_HEIGHT - MENU_HEADER_HEIGHT;

const Cart = (props) => {
  let { cartItemList, orderData, orderType, totalPrice } = props;

  const [cartList, setCartList] = useState([]);

  const orderCartList = useMemo(() => {
    return (
      getOrdersWithTotalPrice(orderData?.cart, props.menuList)?.cartItems ?? []
    );
  }, [orderData?.cart, props.menuList]);

  useEffect(() => {
    let list = [
      {
        status: "confirmed",
        items: orderCartList,
        total: orderData?.totalAmount ?? 0,
      },
      {
        status: "pending",
        items: cartItemList,
        total: totalPrice,
      },
    ];
    setCartList(list);
  }, [cartItemList, orderData, props.menuList, totalPrice]);

  const onCheckout = () => {
    props.onCheckout();
  };

  let item_index = 0;
  return (
    <Grid item xs={12} sx={{ minHeight: window.innerHeight }}>
      {/*<Grid*/}
      {/*  item*/}
      {/*  xs={12}*/}
      {/*  sx={{ backgroundColor: "#FFF" }}*/}
      {/*  className={styles.headerView}*/}
      {/*>*/}
      {/*  <MenuListHeader paddingHorizontal={PADDING_HORIZONTAL} />*/}
      {/*</Grid>*/}
      <Grid
        item
        xs={12}
        px={`${PADDING_HORIZONTAL}px`}
        sx={{
          height:
            CONTENT_HEIGHT +
            (cartItemList.length && orderType == TABLE ? 0 : VIEW_HEIGHT),
        }}
        className={styles.contentView}
        pt={1.5}
      >
        {cartList.map((item, index) => {
          if (item?.items.length == 0) {
            return null;
          }
          item_index = item_index + 1;
          return (
            <MenuCartListItem
              key={index}
              item={item}
              itemIndex={item_index}
              currency={props.currency}
              onAddSubMenuItemToCart={props.onAddSubMenuItemToCart}
            />
          );
        })}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          height:
            FOOTER_HEIGHT -
            (cartItemList.length && orderType == TABLE ? 0 : VIEW_HEIGHT),
          backgroundColor: "#FFF",
        }}
        className={styles.bottomView}
      >
        <Grid
          item
          xs={12}
          sx={{ height: PRICE_HEIGHT }}
          className={styles.billView}
          px={`${PADDING_HORIZONTAL}px`}
        >
          <Typography className={styles.billText}>Your Total Bill</Typography>
          <Typography className={styles.billText}>
            {props.currency} {totalPrice}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            height: cartItemList.length && orderType == TABLE ? VIEW_HEIGHT : 0,
          }}
          className={styles.view1}
        >
          {/*<Typography*/}
          {/*  className={styles.text1}*/}
          {/*  my={0.5}*/}
          {/*  px={`${PADDING_HORIZONTAL}px`}*/}
          {/*>*/}
          {/*  Government taxes as applicable, we levy a 7.5% service change*/}
          {/*</Typography>*/}
          {cartItemList.length && orderType == TABLE ? (
            <Grid
              item
              xs={12}
              sx={{ height: BTN_HEIGHT_2 }}
              px={`${PADDING_HORIZONTAL}px`}
              onClick={props.onConfirmOrder}
            >
              <Box
                className={styles.btnView}
                sx={{ backgroundColor: THEME_COLOR2 }}
              >
                <Typography className={styles.btnText}>
                  Confirm & Order
                </Typography>
              </Box>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          container
          item
          justifyContent="space-between"
          xs={12}
          sx={{ height: BTN_HEIGHT }}
        >
          <Grid
            item
            xs={props.onlineTablePayment ? 5.95 : 12}
            md={props.onlineTablePayment ? 5.99 : 12}
          >
            <Box
              className={styles.btnView}
              sx={{ backgroundColor: THEME_COLOR2 }}
              onClick={props.onRestMenuNavigate}
            >
              <Typography className={styles.btnText}>Back To Menu</Typography>
            </Box>
          </Grid>
          {props.onlineTablePayment ? (
            <Grid item xs={5.95} md={5.99}>
              <Box
                className={styles.btnView}
                sx={{
                  backgroundColor: "#cb6464",
                  ...((orderCartList.length && orderType == TABLE) ||
                  (cartItemList.length && orderType != TABLE)
                    ? {
                        opacity: 1,
                        cursor: "pointer",
                      }
                    : {
                        opacity: 0.5,
                        cursor: "unset",
                      }),
                }}
                onClick={
                  (orderCartList.length && orderType == TABLE) ||
                  (cartItemList.length && orderType != TABLE)
                    ? onCheckout
                    : null
                }
              >
                <Typography className={styles.btnText}>Checkout</Typography>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Cart;
