import * as React from "react";
import Box from "@mui/material/Box";

import { ReactComponent as NextIcon } from "../assets/images/next_icon.svg";

const circleSize = 65;

const AppNextBtn = (props) => {
  return (
    <Box sx={styles.prevNextImgMainView} onClick={() => props.onNextBtnClick()}>
      {/*<Box sx={styles.prevNextImgView}>*/}
      <NextIcon width={circleSize} height={circleSize} />
      {/*<img alt="" src={props.image} style={styles.prevNextImg} />*/}
      {/*</Box>*/}
    </Box>
  );
};

export default AppNextBtn;

const styles = {
  prevNextImgMainView: {
    width: circleSize,
    height: circleSize,
    borderRadius: circleSize / 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    zIndex: 5,
    overflow: "hidden",
  },
  prevNextImgView: {
    width: circleSize - 10,
    height: circleSize - 10,
  },
  prevNextImg: {
    height: "100%",
    width: "100%",
    borderRadius: "50%",
    cursor: "pointer",
    objectFit: "cover",
  },
};
