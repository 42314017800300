import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import MenuListAddSubMenuBtn from "./MenuListAddSubMenuBtn";
import ARWebAllergensNutritions from "./ARWebAllergensNutritions";

import { ReactComponent as CloseIcon } from "../assets/images/close.svg";
import { ReactComponent as RadioOffIcon } from "../assets/images/radio_off2.svg";
import { ReactComponent as RadioOnIcon } from "../assets/images/radio_on2.svg";
import { ReactComponent as InfoIcon } from "../assets/images/info2.svg";

import styles from "./AddMenuToCartModal.module.css";

import { THEME_COLOR, THEME_COLOR2 } from "../containers/MenuList2";
import { CURRENCY_SYMBOL, MENU_TYPE2 } from "../constants";
import {
  checkAllergies,
  checkNutritions,
  onModifierSelect,
  onSetDefaultModifierData,
} from "../constants/commonFunc";

let TOP_VIEW_HEIGHT = 50;
let BOTTOM_MARGIN_BOTTOM = 10;
let BOTTOM_VIEW_HEIGHT = 50;
let CONTENT_VIEW_HEIGHT =
  window.innerHeight -
  TOP_VIEW_HEIGHT -
  BOTTOM_VIEW_HEIGHT -
  BOTTOM_MARGIN_BOTTOM -
  60;
let PADDING_HORIZONTAL = 16;
let BORDER_RADIUS = 8;

const AddMenuToCartModal = (props) => {
  const [cartCount, setCartCount] = useState(
    props.data?.count ? (props.data.count == 0 ? 1 : props.data.count) : 1
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [addedOptions, setAddedOptions] = useState([]);
  const [addedModifiers, setAddedModifiers] = useState([]);
  const [allergensModal, setAllergensModal] = useState(null);

  let { data } = props;

  useEffect(() => {
    setModalVisible(props.visible);
  }, [props.visible]);

  useEffect(() => {
    setCartCount(
      props.data?.count ? (props.data.count == 0 ? 1 : props.data.count) : 1
    );
  }, [props.data]);

  useEffect(() => {
    let data = [];
    if (props.data?.selectedModifiers?.length) {
      data = props.data?.selectedModifiers;
    } else {
      let modifiers = onSetDefaultModifierData(
        props.data?.modifiers,
        props?.menuList,
        "modal"
      );
      data[0] = modifiers;
    }
    setAddedModifiers(data);
  }, [props.data]);

  const addSubMenu = (type) => {
    let count = cartCount;
    if (type == "-" && cartCount > 1) {
      count = cartCount - 1;
      addedOptions.pop();
    }
    if (type == "+") {
      count = cartCount + 1;
      let data1 = [...addedModifiers];
      let modifiers = onSetDefaultModifierData(
        props.data?.modifiers,
        props?.menuList,
        "modal"
      );
      data1[count - 1] = modifiers;
      setAddedModifiers(data1);
    }
    setCartCount(count);
  };

  const onCloseModal = () => {
    props.onClose();
  };

  const onModifierClick = (items, menu_data, index) => {
    let data = [...addedModifiers];
    let value = onModifierSelect(data, items, menu_data, index);
    setAddedModifiers(value);
  };

  const onAddMenuItemToCart = () => {
    props.onAddMenuItemToCart(
      props.data,
      "",
      addedOptions,
      addedModifiers,
      cartCount
    );
  };

  return (
    <SwipeableDrawer
      sx={{
        "& .MuiPaper-root": {
          overflow: "hidden",
          borderTopLeftRadius: "18px",
          borderTopRightRadius: "18px",
        },
      }}
      anchor={"bottom"}
      open={modalVisible}
      disableSwipeToOpen
      onClose={() => onCloseModal()}
      transitionDuration={{
        enter: 300,
        exit: 350,
      }}
    >
      <Box sx={{ backgroundColor: "#EEE" }}>
        <Box
          className={styles.topView}
          px={`${PADDING_HORIZONTAL}px`}
          sx={{ height: TOP_VIEW_HEIGHT }}
        >
          <Box className={styles.titleView}>
            <Typography className={styles.titleText}>{data?.name}</Typography>
          </Box>
          <Box
            onClick={props.onClose}
            sx={{
              cursor: "pointer",
              path: { fill: "#000" },
            }}
          >
            <CloseIcon height={20} width={20} />
          </Box>
        </Box>
        <Box sx={{ maxHeight: CONTENT_VIEW_HEIGHT, overflow: "auto" }}>
          <Box
            className={styles.contentView}
            my={1.5}
            mx={`${PADDING_HORIZONTAL}px`}
            sx={{ borderRadius: `${BORDER_RADIUS}px`, position: "relative" }}
          >
            <Box className={styles.menuImgView}>
              <img className={styles.menuImgStyle} src={data?.images?.[0]} />
            </Box>
            {checkAllergies(data?.allergies) ||
            checkNutritions(data?.nutritions) ? (
              <Box
                className={styles.allergensView}
                p={0.5}
                onClick={() => setAllergensModal(data)}
              >
                <InfoIcon width={20} height={20} />
                <Typography className={styles.allergensText} ml={0.4}>
                  Allergens
                </Typography>
              </Box>
            ) : null}
          </Box>
          <Box
            className={styles.contentView}
            p={`${PADDING_HORIZONTAL}px`}
            my={1.5}
            mx={`${PADDING_HORIZONTAL}px`}
            sx={{ borderRadius: `${BORDER_RADIUS}px` }}
          >
            <Box mb={1} className={styles.menuTypeView}>
              {MENU_TYPE2?.[data?.extras?.menuType] ?? null}
            </Box>
            <Typography
              className={styles.menuNameText}
              sx={{ color: THEME_COLOR2 }}
            >
              {data?.name}
            </Typography>
            <Typography
              className={styles.menuDescText}
              sx={{ color: THEME_COLOR2 }}
            >
              {data?.description}
            </Typography>
          </Box>

          <Box>
            {Array(cartCount)
              .fill(props.data?.modifiers ? props.data.modifiers : [])
              .map((y, index) => {
                return y.map((item, i) => {
                  return (
                    <Box
                      key={i}
                      className={styles.contentView}
                      my={1.5}
                      mx={`${PADDING_HORIZONTAL}px`}
                      sx={{ borderRadius: `${BORDER_RADIUS}px` }}
                      pb={1.5}
                    >
                      <Box
                        className={styles.optionTopMainView}
                        p={`${PADDING_HORIZONTAL}px`}
                      >
                        <Box className={styles.optionTopView}>
                          <Typography
                            className={styles.optionsText}
                            sx={{ color: THEME_COLOR2 }}
                          >
                            {item.header}
                          </Typography>
                          <Typography
                            className={styles.optionsChoseText}
                            sx={{ color: THEME_COLOR2 }}
                          >
                            {item?.multiSelection
                              ? "Select any options"
                              : "Select any 1 option"}
                          </Typography>
                        </Box>
                        {item.required ? (
                          <Box
                            className={styles.requiredView}
                            sx={{ backgroundColor: THEME_COLOR2 }}
                            ml={1}
                            px={1}
                            py={0.5}
                          >
                            <Typography className={styles.requiredText}>
                              required
                            </Typography>
                          </Box>
                        ) : null}
                      </Box>
                      {item.items.map((x, i2) => {
                        let menu_data = null;
                        let menu_name = "";
                        let menu_price = "";
                        let menu_currency = "";
                        let find_index = props.menuList.findIndex(
                          (y) => y?._id == x
                        );
                        if (find_index >= 0) {
                          menu_data = props.menuList?.[find_index];
                          menu_name = menu_data?.name;
                          menu_currency = menu_data?.price?.currency;
                          menu_price = menu_data?.price?.value;
                        }
                        return (
                          <Box
                            key={"i" + i2}
                            className={styles.optionItemView}
                            px={`${PADDING_HORIZONTAL}px`}
                            pt={1.5}
                          >
                            <Box className={styles.optionItemTextView}>
                              <Typography
                                className={styles.optionItemText}
                                sx={{ color: THEME_COLOR2 }}
                              >
                                {menu_name}
                              </Typography>
                            </Box>
                            {menu_price ? (
                              <Typography
                                className={styles.menuPriceText}
                                sx={{ color: THEME_COLOR2 }}
                                mr={1}
                              >
                                {CURRENCY_SYMBOL?.[menu_currency]}
                                {menu_price?.toFixed(2)}
                              </Typography>
                            ) : null}
                            <Box
                              sx={{ cursor: "pointer" }}
                              onClick={() =>
                                onModifierClick(item, menu_data, index)
                              }
                            >
                              {addedModifiers?.[index]?.some(
                                (y) =>
                                  y._idMenuItem == x &&
                                  y._idModifier == item._id
                              ) ? (
                                <RadioOnIcon width={20} height={20} />
                              ) : (
                                <RadioOffIcon width={20} height={20} />
                              )}
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  );
                });
              })}
          </Box>
        </Box>
        {props.addToCartExist ? (
          <Box
            className={styles.bottomBtnView}
            mb={`${BOTTOM_MARGIN_BOTTOM}px`}
            mx={`${PADDING_HORIZONTAL}px`}
            sx={{ height: BOTTOM_VIEW_HEIGHT }}
          >
            <Box className={styles.btnView}>
              <MenuListAddSubMenuBtn
                count={cartCount ? cartCount : 1}
                inputWidth={"100%"}
                inputHeight={"100%"}
                addSubWidth={50}
                onAddSubMenu={(type) => addSubMenu(type)}
              />
            </Box>
            <Box
              className={styles.btnView}
              sx={{ backgroundColor: THEME_COLOR }}
              onClick={onAddMenuItemToCart}
            >
              <Typography className={styles.btnText}>ADD ITEM</Typography>
            </Box>
          </Box>
        ) : null}
        <ARWebAllergensNutritions
          data={allergensModal}
          onClose={() => setAllergensModal(null)}
        />
      </Box>
    </SwipeableDrawer>
  );
};

export default AddMenuToCartModal;
