import React, { useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { THEME_COLOR } from "../containers/MenuList2";

import styles from "./MenuListAddSubMenuBtn.module.css";

const MenuListAddSubMenuBtn = (props) => {
  return (
    <Box
      className={styles.mainView}
      sx={{
        width: props?.inputWidth ? props.inputWidth : 100,
        height: props?.inputHeight ? props.inputHeight : 35,
        backgroundColor: THEME_COLOR,
      }}
    >
      <Box
        className={styles.addSubView}
        onClick={() => props.onAddSubMenu("-")}
        sx={{ width: props?.addSubWidth ? props.addSubWidth : 40 }}
      >
        <Typography className={styles.addSubText}>-</Typography>
      </Box>
      <Box>
        <Typography className={styles.countText}>{props.count}</Typography>
      </Box>
      <Box
        className={styles.addSubView}
        onClick={() => props.onAddSubMenu("+")}
        sx={{ width: props?.addSubWidth ? props.addSubWidth : 40 }}
      >
        <Typography className={styles.addSubText}>+</Typography>
      </Box>
    </Box>
  );
};

export default MenuListAddSubMenuBtn;
