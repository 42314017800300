import * as actionTypes from "./types";

export const updateOrderType = (data) => ({
  type: actionTypes.UPDATE_ORDER_TYPE,
  payload: data,
});

export const updateCartItems = (data) => ({
  type: actionTypes.UPDATE_CART_ITEMS,
  payload: data,
});

export const updateUserAddressList = (data) => ({
  type: actionTypes.UPDATE_USER_ADDRESS_LIST,
  payload: data,
});

export const updateRestaurantDetail = (data) => ({
  type: actionTypes.UPDATE_RESTAURANT_DETAIL,
  payload: data,
});

export const updatePaymentDetail = (data) => ({
  type: actionTypes.UPDATE_PAYMENT_DETAIL,
  payload: data,
});

export const updateLocale = (data) => ({
  type: actionTypes.UPDATE_LOCALE,
  payload: data,
});

export const updateSelectedMenuData = (data) => ({
  type: actionTypes.UPDATE_SELECTED_MENU_DATA,
  payload: data,
});

export const updateLastViewedMenu = (data) => ({
  type: actionTypes.UPDATE_LAST_VIEWED_MENU,
  payload: data,
});

export const updateUserData = (data) => ({
  type: actionTypes.UPDATE_USER_DATA,
  payload: data,
});

export const clearCartData = () => ({
  type: actionTypes.CLEAR_CART_DATA,
});
