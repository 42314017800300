import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import styles from "../components/ARWebBilling.module.css";

import "./../assets/css/custom.css";

import i18next from "../constants/i18n";

let fontStyle1 = {
  temp1: {},
  temp2: {
    fontFamily: "GilroySemiBold !important",
  },
};

const ARWebBilling = (props) => {
  let {
    totalCartPrice,
    subTotal,
    discount,
    currency,
    tipValue,
    taxValue,
    restTemplate,
  } = props;

  let billing_list = [
    {
      label: i18next.t("Subtotal"),
      value: subTotal,
    },
    {
      label: i18next.t("Discount"),
      value: discount,
    },
    {
      label: i18next.t("Tax"),
      value: taxValue,
    },
    {
      label: i18next.t("Tip"),
      value: tipValue,
    },
  ];

  return (
    <Grid item mx={props.page == "checkout" ? 2 : 1} pb={3} mt={1.5}>
      <Box
        p={2}
        className={styles.mainView}
        sx={
          restTemplate == "temp1"
            ? null
            : {
                border: 0,
                background:
                  "linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(270deg, #b99c74 -37.9%, rgba(185, 156, 116, 0.37) 5.96%, rgba(185, 156, 116, 0) 111.15%)",
              }
        }
      >
        <Box className={styles.listView}>
          <Box className={styles.listLabelView}>
            <Typography
              className={styles.billTitle}
              sx={{ ...fontStyle1[restTemplate] }}
            >
              {i18next.t("Your bill")}
            </Typography>
          </Box>
          <Typography
            className={styles.totalVal}
            sx={{ ...fontStyle1[restTemplate] }}
          >
            {currency}
            {totalCartPrice}
          </Typography>
        </Box>
        {billing_list.map((x, i) => {
          return (
            <Box key={i} className={styles.listView} mt={1}>
              <Box className={styles.labelView}>
                <Typography className={styles.labelText}>{x.label}</Typography>
              </Box>
              <Typography className={styles.billVal}>
                {currency}
                {x.value}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Grid>
  );
};

export default ARWebBilling;
