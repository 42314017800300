import { DELIVERY } from "../../constants";

const initialState = {
  orderType: DELIVERY,
  restaurantDetail: null,
  paymentDetail: null,
  userAddressList: [],
  locale: "en",
  cartItems: [],
  selectedMenuData: null,
  lastViewedMenu: null,
  userData: null,
};

const restaurantDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_ORDER_TYPE":
      return {
        ...state,
        orderType: action.payload,
      };
    case "UPDATE_CART_ITEMS":
      return {
        ...state,
        cartItems: action.payload,
      };
    case "UPDATE_USER_ADDRESS_LIST":
      return {
        ...state,
        userAddressList: action.payload,
      };
    case "UPDATE_RESTAURANT_DETAIL":
      return {
        ...state,
        restaurantDetail: action.payload,
      };
    case "UPDATE_PAYMENT_DETAIL":
      return {
        ...state,
        paymentDetail: action.payload,
      };
    case "UPDATE_LOCALE":
      return {
        ...state,
        locale: action.payload,
      };
    case "UPDATE_SELECTED_MENU_DATA":
      return {
        ...state,
        selectedMenuData: action.payload,
      };
    case "UPDATE_LAST_VIEWED_MENU":
      return {
        ...state,
        lastViewedMenu: action.payload,
      };
    case "UPDATE_USER_DATA":
      return {
        ...state,
        userData: action.payload,
      };
    case "CLEAR_CART_DATA":
      return {
        ...state,
        restaurantDetail: null,
        cartItems: [],
      };
  }
  return state;
};

export default restaurantDataReducer;
