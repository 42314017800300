import React from "react";
import { ReactComponent as NonVegIcon } from "../assets/images/nonveg.svg";
import { ReactComponent as VegIcon } from "../assets/images/veg.svg";
import { ReactComponent as PlantBasedIcon } from "../assets/images/plantbased.svg";
import { ReactComponent as SeafoodIcon } from "../assets/images/seafood.svg";
import { ReactComponent as NonVeg2Icon } from "../assets/images/non_veg_icon.svg";
import { ReactComponent as Veg2Icon } from "../assets/images/veg_icon.svg";
import { ReactComponent as NonVeg3Icon } from "../assets/images/nonveg2.svg";
import { ReactComponent as Veg3Icon } from "../assets/images/veg2.svg";

export const THEME_COLOR = "#ffe342";
export const THEME_COLOR3 = "#AAA";
export const LOADING_COLOR = "#000";

export const FOODO_URL = "https://foodo-web.netlify.app";

export let TEMP1REST = [];
export let TEMP3REST = [];

export const CURRENCY_LIST = [
  { label: "Euro (£)", value: "euro", currency: "GBP", symbol: "£" },
  { label: "Dollar ($)", value: "dollar", currency: "USD", symbol: "$" },
  { label: "Dirham (AED)", value: "dirham", currency: "AED", symbol: "د.إ" },
  { label: "Rupee (INR)", value: "rupee", currency: "INR", symbol: "₹" },
  {
    label: "Bahraini dinar (BD)",
    value: "bahraini dinar",
    currency: "BD",
    symbol: "BD",
  },
  {
    label: "Saudi riyal (SR)",
    value: "saudi riyal",
    currency: "SR",
    symbol: "SR",
  },
];

export const CURRENCY_SYMBOL = CURRENCY_LIST.reduce(
  (o, key) => ({ ...o, [key.currency]: key.symbol }),
  {}
);

export const MENU_TYPE = [
  { title: "Non vegetarian", icon: <NonVegIcon /> },
  { title: "Vegetarian", icon: <VegIcon /> },
  { title: "Plant based", icon: <PlantBasedIcon /> },
  { title: "Seafood", icon: <SeafoodIcon /> },
];

export const MENU_TYPE2 = {
  "Non vegetarian": <NonVeg2Icon />,
  "Vegetarian": <Veg2Icon />,
  "Plant based": <PlantBasedIcon />,
  "Seafood": <SeafoodIcon />,
};

export const MENU_TYPE3 = {
  "Non vegetarian": <NonVeg3Icon />,
  "Vegetarian": <Veg3Icon />,
  "Plant based": <PlantBasedIcon />,
  "Seafood": <SeafoodIcon />,
};

export const DINEIN = 1;
export const DELIVERY = 2;
export const PICKUP = 3;
export const TABLE = 4;

export const FIREBASE_CONFIG = JSON.parse(
  process.env.REACT_APP_FIREBASE_CONFIG
);

export const APP_TYPE_FOODO = "FOODO";
export const APP_TYPE_GOPOSH = "GOPOSH";
export const APP_TYPE_BOTH = "BOTH";
