import moment from "moment";
import { MENU_TYPE2 } from "./index";

export const checkRestIsClosed = (openHrs) => {
  const format = "HH:mm";
  const day = moment().format("dddd").toLowerCase();
  const time_data = openHrs?.[day];

  if (time_data?.isOpen && time_data?.timings.length) {
    const { from, to } = time_data.timings[0];
    const fromTime = moment(from, format);
    const toTime = moment(to, format);
    const todayTime = moment();

    return !todayTime.isBetween(fromTime, toTime, null, "[]");
  }

  return true;
};

export const checkAllergies = (allergies) => {
  return !(
    allergies?.toUpperCase() == "NA" ||
    allergies == "0" ||
    allergies == null ||
    allergies == ""
  );
};

export const checkNutritions = (nutritions) => {
  return !(
    nutritions?.toUpperCase() == "NA" ||
    nutritions == "0" ||
    nutritions == null ||
    nutritions == ""
  );
};

export const onModifierSelect = (data, items, menu, index) => {
  let opt = menu._id;
  let item = items.items;

  let menu_data = {
    _idModifier: items._id,
    _idMenuItem: opt,
    name: menu?.name,
    price: menu?.price,
  };
  if (typeof data[index] == "undefined") {
    data[index] = [menu_data];
  } else {
    let existItem = item.filter((x) =>
      data[index].some((y) => y._idMenuItem == x && y._idModifier == items._id)
    );

    if (existItem.length && !items?.multiSelection) {
      if (!existItem.includes(opt)) {
        data[index] = data[index].filter(
          (x) =>
            !existItem.includes(x._idMenuItem) && x._idModifier != items._id
        );
      }
    }

    if (
      data[index].some(
        (y) => y._idMenuItem == opt && y._idModifier == items._id
      )
    ) {
      let filter_data = data[index].filter(
        (x) => !(x._idMenuItem == opt && x._idModifier == items._id)
      );
      if (
        !items.required ||
        (items.multiSelection &&
          filter_data.filter((x) => x._idModifier == items._id).length)
      ) {
        data[index] = filter_data;
      }
    } else {
      data[index] = [...data[index], menu_data];
    }
  }

  return data;
};

export const onSetDefaultModifierData = (modifierList, menuList, page) => {
  let modifiers = [];
  modifierList?.map((x) => {
    let default_mod = x?.defaultSelection ?? "";
    if ((x.required && x.items.length) || (default_mod && page == "modal")) {
      if (!default_mod) {
        default_mod = x.items[0];
      }

      let menu_name = "";
      let menu_price = "";

      let find_index = menuList.findIndex((y) => y?._id == default_mod);
      if (find_index >= 0) {
        let menu_data = menuList?.[find_index];
        menu_name = menu_data?.name;
        menu_price = menu_data?.price;
      }

      modifiers.push({
        _idModifier: x._id,
        _idMenuItem: default_mod,
        name: menu_name,
        price: menu_price,
      });
    }
  });
  return modifiers;
};

export const getModifierTotalPrice = (modifiers, modList) => {
  let menuTotal = 0;
  modifiers
    ?.filter((item) => modList?.map((x) => x?._idModifier)?.includes(item))
    ?.map((item) => {
      const objMax = modList
        ?.filter((item1) => item == item1?._idModifier)
        ?.reduce(
          (max, curren) =>
            max?.price?.value > curren?.price?.value ? max : curren,
          null
        );
      menuTotal += objMax?.price?.value ?? 0;
    });
  modList
    ?.filter((item) => !modifiers.includes(item?._idModifier))
    ?.map((item) => {
      menuTotal += item?.price?.value ?? 0;
    });
  return menuTotal;
};

export const getCartItemsWithTotalPrice = (order, menuList) => {
  const qty = order?.quantity ?? order?.count;
  let itemTotal = order.price.value * (qty ? qty : 1);

  const selectedModifiers =
    order?.selectedModifiers ??
    order?.modifiers.map((mod) => {
      return JSON.parse(mod)?.map((modifier) => {
        const menuItem = menuList.find(
          (menu) => menu?._id === modifier?._idMenuItem
        );
        const menuName = menuItem ? menuItem.name : "";
        return { ...modifier, name: menuName };
      });
    });

  let modifiers =
    order?.selectedModifiers && order?.modifiers ? order?.modifiers : [];
  if (modifiers?.length == 0) {
    modifiers =
      menuList.find((x) => x?._id == (order?._idMenuItem ?? order?._id))
        ?.modifiers ?? [];
  }

  const modifierList = modifiers
    ?.filter((x) => !x.addUpPrices)
    ?.map((x) => x?._id);
  selectedModifiers?.map((mod) => {
    itemTotal += getModifierTotalPrice(modifierList, mod);
  });

  return order?._idMenuItem
    ? {
        _id: order?._idMenuItem,
        name: order?.menuItemName,
        price: order?.price,
        note: order?.notes,
        count: order?.quantity,
        modifiers: modifiers,
        selectedModifiers: selectedModifiers,
        totalPrice: itemTotal,
      }
    : {
        ...order,
        totalPrice: itemTotal,
      };
};

export const getOrdersWithTotalPrice = (cartItemsList, menuList) => {
  let cartItems = [];
  let total = 0;
  cartItemsList?.forEach((order) => {
    const data = getCartItemsWithTotalPrice(order, menuList);
    total += data?.totalPrice ?? 0;
    cartItems.push(data);
  });
  return { cartItems, totalPrice: total };
};

export const getRequiredModifierPrice = (modifiers, menuList) => {
  return (
    menuList.find(
      (menu) =>
        menu?._id ===
        modifiers.find((item) => item?.required && item?.items?.length)
          ?.items?.[0]
    )?.price?.value ?? 0
  );
};

export const filterMenuType = (menuList) => {
  let menuTypeList = [];
  if (menuList.length) {
    menuList.forEach((item) => {
      if (
        item.extras?.menuType &&
        !menuTypeList.includes(item.extras?.menuType)
      ) {
        menuTypeList.push(item.extras?.menuType);
      }
    });

    const menuTypes = Object.keys(MENU_TYPE2);
    menuTypeList = [
      ...menuTypes.filter((x) => menuTypeList.includes(x)),
      ...menuTypeList.filter((x) => !menuTypes.includes(x)),
    ];
  }
  return menuTypeList;
};
