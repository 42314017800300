import React, { useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import MenuListAddSubMenuBtn from "./MenuListAddSubMenuBtn";

import { ReactComponent as UpArrowIcon } from "../assets/images/up_arrow_black.svg";
import { ReactComponent as DownArrowIcon } from "../assets/images/down_arrow_black.svg";
import { ReactComponent as DeleteIcon } from "../assets/images/delete.svg";

import { CURRENCY_SYMBOL } from "../constants";
import { THEME_COLOR2 } from "../containers/MenuList2";

import styles from "./MenuCartListItem.module.css";
import { getCartItemsWithTotalPrice } from "../constants/commonFunc";

const MenuCartListItem = (props) => {
  const [showOrder, setShowOrder] = useState(true);

  let { item, itemIndex } = props;
  let item_qty = 0;

  const MenuItemList = ({ menu }) => {
    const currency = CURRENCY_SYMBOL?.[menu?.price?.currency];
    const menuTotal = useMemo(() => {
      return getCartItemsWithTotalPrice(menu, [])?.totalPrice ?? 0;
    }, [item]);

    let counts = {};
    menu?.selectedModifiers?.forEach((mod_list) => {
      mod_list?.forEach((mod) => {
        let menu_name = mod?.name;
        if (counts?.[menu_name]) {
          counts[menu_name] += 1;
        } else {
          counts = {
            ...counts,
            [menu_name]: 1,
          };
        }
      });
    });

    return (
      <Box className={styles.itemView}>
        <Box className={styles.itemDataView} pr={1}>
          <Typography className={styles.itemName}>{menu.name}</Typography>
          {menu?.selectedModifiers?.length ? (
            <Box mt={1} display={"flex"} flexDirection={"column"}>
              {Object.keys(counts).map((key, index) => {
                return (
                  <Typography key={index} className={styles.noteText}>
                    {counts[key]} x {key}
                  </Typography>
                );
              })}
            </Box>
          ) : null}
          <Typography mt={0.5} className={styles.itemPrice}>
            {currency} {menuTotal.toFixed(2)}
          </Typography>
        </Box>
        {item.status === "confirmed" ? null : (
          <DeleteIcon
            width={25}
            height={25}
            onClick={() =>
              props.onAddSubMenuItemToCart("-", { ...menu, count: 1 })
            }
          />
        )}
        <Box className={styles.itemQtyView} pl={1}>
          {item.status === "confirmed" ? (
            <Typography className={styles.itemQty}>{menu.count}</Typography>
          ) : (
            <MenuListAddSubMenuBtn
              count={menu?.count ? menu.count : 1}
              onAddSubMenu={(type) => props.onAddSubMenuItemToCart(type, menu)}
            />
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Grid item xs={12} className={styles.cartListItem} mb={1.5}>
      <Box className={styles.cartTitleView}>
        <Box className={styles.itemTitleView}>
          <Typography className={styles.itemTitle}>Order{itemIndex}</Typography>
        </Box>
        <Box className={styles.statusExpandView}>
          <Typography
            mr={1}
            className={styles.itemStatus}
            sx={{
              color:
                item.status == "confirmed" ? "green !important" : THEME_COLOR2,
            }}
          >
            ({item.status})
          </Typography>
          {showOrder ? (
            <UpArrowIcon
              width={15}
              height={15}
              onClick={() => setShowOrder(false)}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <DownArrowIcon
              width={15}
              height={15}
              onClick={() => setShowOrder(true)}
              style={{ cursor: "pointer" }}
            />
          )}
        </Box>
      </Box>
      {showOrder ? (
        <Box className={styles.cartView}>
          {item.items.map((menu, index2) => {
            item_qty += Number(menu.count);
            return <MenuItemList key={index2} menu={menu} />;
          })}
          <Box className={styles.itemTotalQtyView}>
            <Box className={styles.itemTotalView}>
              <Typography className={styles.itemTotal}>
                Total : {props.currency} {Number(item?.total).toFixed(2)}
              </Typography>
            </Box>
            <Box className={styles.itemQtyView1}>
              <Typography className={styles.itemQty1}>
                QTY : {item_qty}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : null}
    </Grid>
  );
};

export default MenuCartListItem;
