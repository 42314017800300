import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";

import styles from "../components/ARWebPayment.module.css";

import ARWebCartTextInput from "./ARWebCartTextInput";

import "./../assets/css/custom.css";

import {
  THEME_COLOR2,
  THEME_COLOR as THEMECOLOR,
} from "../containers/MenuList2";
import { THEME_COLOR } from "../constants";
import i18next from "../constants/i18n";

import { ReactComponent as GPayIcon } from "../assets/images/gpay.svg";
import { ReactComponent as GPay2Icon } from "../assets/images/gpay2.svg";
import { ReactComponent as ApplePayIcon } from "../assets/images/apple_pay.svg";
import { ReactComponent as ApplePay2Icon } from "../assets/images/apple_pay2.svg";
import { ReactComponent as CreditCardIcon } from "../assets/images/credit_card.svg";
import { ReactComponent as ArrowUpRightIcon } from "../assets/images/arrow_up_right.svg";
import { ReactComponent as EditIcon } from "../assets/images/edit.svg";
import { ReactComponent as QueIcon } from "../assets/images/question.svg";

let payment_method = [
  {
    value: "gpay",
    icon: <GPayIcon width={44} height={44} />,
    icon2: <GPay2Icon width={44} height={44} />,
  },
  {
    value: "apple_pay",
    icon: <ApplePayIcon width={44} height={44} />,
    icon2: <ApplePay2Icon width={44} height={44} />,
  },
  {
    value: "card",
    icon: <CreditCardIcon width={35} height={35} />,
    icon2: (
      <Box sx={{ path: { stroke: "#FFF" } }}>
        <CreditCardIcon width={35} height={35} />
      </Box>
    ),
  },
];

let InputViewStyle = {
  backgroundColor: "#FCFCFD",
  border: "1px solid #CFD8E8",
  borderRadius: "8px",
};

let tip_list = [5, 10, 15, "edit"];

let bgColor = {
  temp1: THEME_COLOR,
  temp2: THEME_COLOR2,
};

let textColor = {
  temp1: "#000",
  temp2: "#FFF",
};

let editColor = {
  temp1: "#000",
  temp2: "#FFF",
};

let splitPayment = {
  temp1: {
    border: "1px solid #f7e574",
    borderRadius: "16px",
  },
  temp2: {
    border: "1px solid #999999",
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
  },
};

let fontStyle1 = {
  temp1: {},
  temp2: {
    fontFamily: "GilroySemiBold !important",
  },
};

const ARWebPayment = (props) => {
  let { paymentDetail, restTemplate } = props;

  const [paymentMethod, setPaymentMethod] = useState(
    paymentDetail?.method ? paymentDetail?.method : "card"
  );
  const [cardHolderName, setCardHolderName] = useState(
    paymentDetail.cardHolderName
  );
  const [cardNumber, setCardNumber] = useState("");
  const [cardMonth, setCardMonth] = useState("");
  const [cardYear, setCardYear] = useState("");
  const [securityCode, setSecurityCode] = useState("");
  const [rememberMyCard, setRememberMyCard] = useState(false);
  const [tipValue, setTipValue] = useState(0);

  useEffect(() => {
    setPaymentMethod(paymentDetail.method);
    setCardHolderName(paymentDetail.cardHolderName);
    setCardNumber(paymentDetail.cardNumber);
    setCardMonth(paymentDetail.expMonth);
    setCardYear(paymentDetail.expYear);
    setSecurityCode(paymentDetail.securityCode);
  }, [props.paymentDetail]);

  const onSelectPaymentMethod = (val) => {
    setPaymentMethod(val.method);
    props.updatePaymentDetail("method", val.value);
  };

  const onTipSelect = (tip) => {
    let val = tip;
    if (tip == tipValue) {
      val = 0;
    }
    setTipValue(val);
    if (tip != "edit") {
      props.onSetTipValue(val);
    }
  };

  return (
    <Grid
      container
      item
      mt={4}
      xs={12}
      justifyContent={"center"}
      px={2}
      className={styles.mainView}
    >
      <Grid item xs={12}>
        <Box
          className={styles.splitPaymentView}
          sx={{ cursor: { xl: "pointer" }, ...splitPayment[restTemplate] }}
          py={1.5}
          px={2}
        >
          <Typography
            className={styles.subTitleText}
            sx={{ ...fontStyle1[restTemplate] }}
          >
            {i18next.t("Split Payment")}
          </Typography>
          <ArrowUpRightIcon
            width={25}
            height={25}
            style={{ transform: props.locale == "ar" ? "scaleX(-1)" : "unset" }}
          />
        </Box>

        <Box my={2}>
          <Box>
            <Typography
              className={styles.subTitleText}
              sx={{ ...fontStyle1[restTemplate] }}
            >
              {i18next.t("Tips")}
            </Typography>
            <Typography className={styles.tipDescText}>
              {i18next.t("Thankyou for your generous tips for future orders.")}
              &lrm;
            </Typography>
            <Box className={styles.tipListMainView} mt={2}>
              {tip_list.map((y, i) => {
                return (
                  <Box
                    key={i}
                    onClick={() => onTipSelect(y)}
                    className={styles.tipListView}
                    sx={{
                      cursor: { xl: "pointer" },
                      path: {
                        fill:
                          tipValue == y
                            ? editColor[restTemplate]
                            : restTemplate == "temp1"
                            ? "#000"
                            : THEMECOLOR,
                      },
                    }}
                    style={{
                      width: window.innerWidth / 5.5,
                      height: window.innerWidth / 5.5,
                      backgroundColor:
                        tipValue == y ? bgColor[restTemplate] : "#FFFFFF",
                    }}
                  >
                    {y == "edit" ? (
                      <EditIcon width={35} height={35} />
                    ) : (
                      <Typography
                        className={styles.tipListText}
                        sx={{
                          color:
                            tipValue == y ? textColor[restTemplate] : "#000",
                          ...fontStyle1[restTemplate],
                        }}
                      >
                        {props.currency}
                        {y}
                      </Typography>
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} px={2} className={styles.paymentMethodMainView}>
        <Box pt={2}>
          <Typography
            className={styles.subTitleText}
            sx={{ ...fontStyle1[restTemplate] }}
          >
            {i18next.t("Payment Method")}
          </Typography>
          <Box className={styles.paymentMethodView}>
            {payment_method.map((x, i) => {
              return (
                <Box
                  key={i}
                  mt={1.5}
                  className={styles.paymentMethod}
                  sx={{ cursor: { xl: "pointer" } }}
                  style={{
                    width: window.innerWidth / 4,
                    height: window.innerWidth / 4,
                    backgroundColor:
                      x.value == paymentMethod
                        ? bgColor[restTemplate]
                        : "#FFFFFF",
                  }}
                  onClick={() => onSelectPaymentMethod(x)}
                >
                  {restTemplate == "temp1"
                    ? x.icon
                    : x.value == paymentMethod
                    ? x.icon2
                    : x.icon}
                </Box>
              );
            })}
          </Box>

          <Box pb={2} mt={2}>
            <Box mt={2}>
              <ARWebCartTextInput
                page={"checkout"}
                locale={props.locale}
                value={cardHolderName}
                name={"CardHolder"}
                setValue={(value) => {
                  setCardHolderName(value);
                  props.updatePaymentDetail("cardHolderName", value);
                }}
                label={i18next.t("Card Holder's Name")}
                labelIcon={
                  <QueIcon
                    width={15}
                    height={15}
                    style={{ cursor: "pointer" }}
                  />
                }
                inputViewStyle={InputViewStyle}
              />
            </Box>
            <Box mt={2}>
              <ARWebCartTextInput
                page={"checkout"}
                locale={props.locale}
                value={cardNumber}
                name={"CardNumber"}
                setValue={(value) => {
                  setCardNumber(value.replace(/[^0-9]/g, ""));
                  props.updatePaymentDetail(
                    "cardNumber",
                    value.replace(/[^0-9]/g, "")
                  );
                }}
                label={i18next.t("Card Number")}
                labelIcon={
                  <QueIcon
                    width={15}
                    height={15}
                    style={{ cursor: "pointer" }}
                  />
                }
                inputViewStyle={InputViewStyle}
              />
            </Box>
            <Box
              mt={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ width: "48%" }}>
                <ARWebCartTextInput
                  page={"checkout"}
                  locale={props.locale}
                  value={cardMonth}
                  name={"cardExpiryMonth"}
                  setValue={(value) => {
                    let val = value.substring(0, 2).replace(/[^0-9]/g, "");
                    setCardMonth(val);
                    props.updatePaymentDetail("expMonth", val);
                  }}
                  label={i18next.t("Expire Month")}
                  inputViewStyle={InputViewStyle}
                />
              </Box>
              <Box sx={{ width: "48%" }}>
                <ARWebCartTextInput
                  page={"checkout"}
                  locale={props.locale}
                  value={cardYear}
                  name={"cardExpiryYear"}
                  setValue={(value) => {
                    let val = value.substring(0, 4).replace(/[^0-9]/g, "");
                    setCardYear(val);
                    props.updatePaymentDetail("expYear", val);
                  }}
                  label={i18next.t("Expire Year")}
                  inputViewStyle={InputViewStyle}
                />
              </Box>
            </Box>
            <Box mt={2} sx={{ width: "48%" }}>
              <ARWebCartTextInput
                page={"checkout"}
                locale={props.locale}
                value={securityCode}
                name={"CVV"}
                setValue={(value) => {
                  setSecurityCode(value.replace(/[^0-9]/g, ""));
                  props.updatePaymentDetail(
                    "securityCode",
                    value.replace(/[^0-9]/g, "")
                  );
                }}
                label={i18next.t("CVV")}
                inputViewStyle={InputViewStyle}
              />
            </Box>

            <Box
              mt={1}
              className={styles.rememberCardView}
              onClick={() => setRememberMyCard(!rememberMyCard)}
            >
              <Checkbox
                checked={rememberMyCard}
                onChange={() => setRememberMyCard(!rememberMyCard)}
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 28,
                    color: rememberMyCard
                      ? restTemplate == "temp1"
                        ? THEME_COLOR
                        : THEMECOLOR
                      : "#EEEEEE",
                  },
                  paddingLeft: props.locale == "ar" ? 1 : 0,
                  paddingRight: props.locale == "ar" ? 0 : 1,
                  "&.Mui-checked": {
                    color:
                      restTemplate == "temp1"
                        ? THEME_COLOR + "!important"
                        : THEMECOLOR + "!important",
                  },
                }}
              />
              <Typography className={styles.rememberCardText}>
                {i18next.t("Remember my card for next purchases.")}&lrm;
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ARWebPayment;
